import 'react-lazy-load-image-component/src/effects/opacity.css';

import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ComponentProps, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useModal } from 'react-modal-hook';
import { useIntersection } from 'react-use';

import { useEvent, useMomentEngagementMetrics, useMomentReactions } from '../../hooks';
import { Event, EventMoment, EventRole, Reaction, User } from '../../models';
import Comments from '../../pages/Moments/Comments';
import { usePotluck, useUser } from '../../providers';
import { fadeInAnimation } from '../animations';
import { Button } from '../Button';
import { ChatIcon, Heart, HeartFilled, Trash } from '../icons';
import { Loader } from '../Loader';
import { Modal } from '../Modal';

interface MomentFeedProps {
  event: Event;
  userRole: EventRole;
}

type MomentProps = ComponentProps<'div'> & {
  event: Event;
  moment: EventMoment;
  userRole: EventRole;
};

const Image = styled(LazyLoadImage)`
  ${fadeInAnimation()}
`;

export function Moment({ event, moment, userRole, ...props }: MomentProps) {
  const element = useRef<HTMLDivElement>(null);
  const { deleteMoment } = useEvent(Number(event.id));
  const { user } = useUser();
  const intersection = useIntersection(element, {
    threshold: 1,
    rootMargin: '100px'
  });

  const { metrics, mutate } = useMomentEngagementMetrics(
    event.id,
    moment.id,
    !!intersection?.isIntersecting
  );

  const { reaction, react, unreact } = useMomentReactions(event.id, moment.id);

  const toggleReaction = async () => {
    await (!reaction ? react(Reaction.Like) : unreact(reaction.id));
    if (!metrics) {
      return;
    }

    await mutate({
      ...metrics,
      reactions: { like: metrics.reactions.like + (!reaction ? 1 : -1) }
    });
  };

  const canDeleteMoment = (role: EventRole, currentUser?: User) =>
    role === EventRole.Organizer ||
    role === EventRole.Cohost ||
    currentUser.id === moment.creator.id;
  
  const [openConfirmDeleteMoment, closeConfirmDeleteMoment] = useModal(
    () => (
      <Modal onClose={closeConfirmDeleteMoment}>
        {({ close }) => (
          <div className="flex flex-col text-center space-y-4">
            <p dangerouslySetInnerHTML={{ __html: "Are you sure?" }}></p>
            <div className="flex flex-col justify-evenly sm:flex-row">
              <Button
                onClick={async () => {
                  await deleteMoment(event, moment.id);
                  close();
                }}
              >
                Delete this Photo
              </Button>
              <Button className="mt-2" outline onClick={close}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
    ),
    [moment]
  );

  return (
    <div {...props} ref={element}>
      {moment.imageUrl && (
        <Image src={moment.imageUrl} alt="moment" className="rounded-3xl" effect="opacity" />
      )}
      {!!metrics && (
        <>
          <div className="flex items-center my-4 space-x-8">
            <div className="flex items-center cursor-pointer space-x-2">
              <ChatIcon />
              <div>
                {metrics.comments ? (metrics.comments > 0 ? metrics.comments : '') : ''}{' '}
                {metrics.comments && metrics.comments > 1 ? 'Comments' : 'Comment'}
              </div>
            </div>
            <div className="flex items-center cursor-pointer space-x-2" onClick={toggleReaction}>
              {reaction ? <HeartFilled /> : <Heart />}
              <div>
                {metrics.reactions?.like
                  ? metrics.reactions.like > 0
                    ? metrics.reactions.like
                    : ''
                  : ''}{' '}
                {metrics.reactions?.like && metrics?.reactions?.like > 1 ? 'Likes' : 'Like'}
              </div>
            </div>
            {
              canDeleteMoment(userRole, user) &&
              <div className="flex items-center cursor-pointer space-x-2"
                   onClick={() => openConfirmDeleteMoment()}
              >
              <Trash className="mr-1" />Delete
            </div>}
          </div>
          <Comments
            event={event}
            moment={moment}
            onComment={() =>
              metrics && mutate({ ...metrics, comments: metrics.comments + 1 }, false)
            }
          />
        </>
      )}
    </div>
  );
}

export const MomentFeed = observer(({ event, userRole }: MomentFeedProps) => {
  const { moments } = usePotluck();
  const feed = moments.feed(event.id);

  return (
    <div className="py-4 space-y-4" ref={feed.register}>
      {feed.data?.length === 0 && <div>No photos yet.</div>}
      {feed.data?.map((m) => (
        <Moment key={m.id} event={event} moment={m} userRole={userRole}/>
      ))}
      {feed.loading && <Loader>Loading!</Loader>}
    </div>
  );
});

export default MomentFeed;
